@file:Suppress("unused", "BooleanLiteralArgument")

package world.phantasmal.psolib.asm

val OPCODES: Array<Opcode?> = Array(256) { null }
val OPCODES_F8: Array<Opcode?> = Array(256) { null }
val OPCODES_F9: Array<Opcode?> = Array(256) { null }

val OP_NOP = Opcode(
    code = 0x00,
    mnemonic = "nop",
    doc = "No operation, does nothing.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x00] = it }

val OP_RET = Opcode(
    code = 0x01,
    mnemonic = "ret",
    doc = "Returns control to caller.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x01] = it }

val OP_SYNC = Opcode(
    code = 0x02,
    mnemonic = "sync",
    doc = "Yields control for the rest of the current frame. Execution will continue the following frame.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x02] = it }

val OP_EXIT = Opcode(
    code = 0x03,
    mnemonic = "exit",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x03] = it }

val OP_THREAD = Opcode(
    code = 0x04,
    mnemonic = "thread",
    doc = "Starts a new thread. Thread execution will start at the given label.\nOften used to check a register every frame. Make sure to yield control with sync when looping.",
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x04] = it }

val OP_VA_START = Opcode(
    code = 0x05,
    mnemonic = "va_start",
    doc = "Initializes a variable argument list.\nMake sure to call va_end after va_start and va_call.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x05] = it }

val OP_VA_END = Opcode(
    code = 0x06,
    mnemonic = "va_end",
    doc = "Restores the registers overwritten by arg_push* instructions.\nCalled after va_call.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x06] = it }

val OP_VA_CALL = Opcode(
    code = 0x07,
    mnemonic = "va_call",
    doc = "Calls the variable argument function at the given label.\nCalled after initializing the argument list with va_start and pushing arguments onto the stack with arg_push* instructions. Make sure to call va_end afterwards.",
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x07] = it }

val OP_LET = Opcode(
    code = 0x08,
    mnemonic = "let",
    doc = "Sets a register to a given register's value.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x08] = it }

val OP_LETI = Opcode(
    code = 0x09,
    mnemonic = "leti",
    doc = "Sets a register to the given value.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x09] = it }

val OP_LETB = Opcode(
    code = 0x0A,
    mnemonic = "letb",
    doc = "Sets a register to the given value.",
    params = listOf(
        Param(RegType(listOf(
            Param(ByteType, null, null, false, true),
        )), null, null, false, false),
        Param(ByteType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x0A] = it }

val OP_LETW = Opcode(
    code = 0x0B,
    mnemonic = "letw",
    doc = "Sets a register to the given value.",
    params = listOf(
        Param(RegType(listOf(
            Param(ShortType, null, null, false, true),
        )), null, null, false, false),
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x0B] = it }

val OP_LETA = Opcode(
    code = 0x0C,
    mnemonic = "leta",
    doc = "Sets a register to the memory address of a given register. Not used by Sega.",
    params = listOf(
        Param(RegType(listOf(
            Param(PointerType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x0C] = it }

val OP_LETO = Opcode(
    code = 0x0D,
    mnemonic = "leto",
    doc = "Sets a register to the memory address of the given label. Not used by Sega.",
    params = listOf(
        Param(RegType(listOf(
            Param(PointerType, null, null, false, true),
        )), null, null, false, false),
        Param(LabelType.Instance, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x0D] = it }

val OP_SET = Opcode(
    code = 0x10,
    mnemonic = "set",
    doc = "Sets a register to 1.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x10] = it }

val OP_CLEAR = Opcode(
    code = 0x11,
    mnemonic = "clear",
    doc = "Sets a register to 0.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x11] = it }

val OP_REV = Opcode(
    code = 0x12,
    mnemonic = "rev",
    doc = "Sets a register to 1 if its current value is 0, otherwise sets it to 0.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x12] = it }

val OP_GSET = Opcode(
    code = 0x13,
    mnemonic = "gset",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x13] = it }

val OP_GCLEAR = Opcode(
    code = 0x14,
    mnemonic = "gclear",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x14] = it }

val OP_GREV = Opcode(
    code = 0x15,
    mnemonic = "grev",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x15] = it }

val OP_GLET = Opcode(
    code = 0x16,
    mnemonic = "glet",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x16] = it }

val OP_GGET = Opcode(
    code = 0x17,
    mnemonic = "gget",
    doc = "Sets a register to value of the given flag.",
    params = listOf(
        Param(ShortType, null, null, false, false),
        Param(RegType(listOf(
            Param(ShortType, "flag", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x17] = it }

val OP_ADD = Opcode(
    code = 0x18,
    mnemonic = "add",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x18] = it }

val OP_ADDI = Opcode(
    code = 0x19,
    mnemonic = "addi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x19] = it }

val OP_SUB = Opcode(
    code = 0x1A,
    mnemonic = "sub",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1A] = it }

val OP_SUBI = Opcode(
    code = 0x1B,
    mnemonic = "subi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1B] = it }

val OP_MUL = Opcode(
    code = 0x1C,
    mnemonic = "mul",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1C] = it }

val OP_MULI = Opcode(
    code = 0x1D,
    mnemonic = "muli",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1D] = it }

val OP_DIV = Opcode(
    code = 0x1E,
    mnemonic = "div",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1E] = it }

val OP_DIVI = Opcode(
    code = 0x1F,
    mnemonic = "divi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x1F] = it }

val OP_AND = Opcode(
    code = 0x20,
    mnemonic = "and",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x20] = it }

val OP_ANDI = Opcode(
    code = 0x21,
    mnemonic = "andi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x21] = it }

val OP_OR = Opcode(
    code = 0x22,
    mnemonic = "or",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x22] = it }

val OP_ORI = Opcode(
    code = 0x23,
    mnemonic = "ori",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x23] = it }

val OP_XOR = Opcode(
    code = 0x24,
    mnemonic = "xor",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x24] = it }

val OP_XORI = Opcode(
    code = 0x25,
    mnemonic = "xori",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x25] = it }

val OP_MOD = Opcode(
    code = 0x26,
    mnemonic = "mod",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x26] = it }

val OP_MODI = Opcode(
    code = 0x27,
    mnemonic = "modi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x27] = it }

val OP_JMP = Opcode(
    code = 0x28,
    mnemonic = "jmp",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x28] = it }

val OP_CALL = Opcode(
    code = 0x29,
    mnemonic = "call",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x29] = it }

val OP_JMP_ON = Opcode(
    code = 0x2A,
    mnemonic = "jmp_on",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
        Param(RegVarType, null, null, true, false),
    ),
    stack = null,
    varargs = true,
    known = true,
).also { OPCODES[0x2A] = it }

val OP_JMP_OFF = Opcode(
    code = 0x2B,
    mnemonic = "jmp_off",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
        Param(RegVarType, null, null, true, false),
    ),
    stack = null,
    varargs = true,
    known = true,
).also { OPCODES[0x2B] = it }

val OP_JMP_E = Opcode(
    code = 0x2C,
    mnemonic = "jmp_=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x2C] = it }

val OP_JMPI_E = Opcode(
    code = 0x2D,
    mnemonic = "jmpi_=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x2D] = it }

val OP_JMP_NE = Opcode(
    code = 0x2E,
    mnemonic = "jmp_!=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x2E] = it }

val OP_JMPI_NE = Opcode(
    code = 0x2F,
    mnemonic = "jmpi_!=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x2F] = it }

val OP_UJMP_G = Opcode(
    code = 0x30,
    mnemonic = "ujmp_>",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x30] = it }

val OP_UJMPI_G = Opcode(
    code = 0x31,
    mnemonic = "ujmpi_>",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x31] = it }

val OP_JMP_G = Opcode(
    code = 0x32,
    mnemonic = "jmp_>",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x32] = it }

val OP_JMPI_G = Opcode(
    code = 0x33,
    mnemonic = "jmpi_>",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x33] = it }

val OP_UJMP_L = Opcode(
    code = 0x34,
    mnemonic = "ujmp_<",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x34] = it }

val OP_UJMPI_L = Opcode(
    code = 0x35,
    mnemonic = "ujmpi_<",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x35] = it }

val OP_JMP_L = Opcode(
    code = 0x36,
    mnemonic = "jmp_<",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x36] = it }

val OP_JMPI_L = Opcode(
    code = 0x37,
    mnemonic = "jmpi_<",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x37] = it }

val OP_UJMP_GE = Opcode(
    code = 0x38,
    mnemonic = "ujmp_>=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x38] = it }

val OP_UJMPI_GE = Opcode(
    code = 0x39,
    mnemonic = "ujmpi_>=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x39] = it }

val OP_JMP_GE = Opcode(
    code = 0x3A,
    mnemonic = "jmp_>=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3A] = it }

val OP_JMPI_GE = Opcode(
    code = 0x3B,
    mnemonic = "jmpi_>=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3B] = it }

val OP_UJMP_LE = Opcode(
    code = 0x3C,
    mnemonic = "ujmp_<=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3C] = it }

val OP_UJMPI_LE = Opcode(
    code = 0x3D,
    mnemonic = "ujmpi_<=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3D] = it }

val OP_JMP_LE = Opcode(
    code = 0x3E,
    mnemonic = "jmp_<=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3E] = it }

val OP_JMPI_LE = Opcode(
    code = 0x3F,
    mnemonic = "jmpi_<=",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x3F] = it }

val OP_SWITCH_JMP = Opcode(
    code = 0x40,
    mnemonic = "switch_jmp",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelVarType, null, null, false, false),
    ),
    stack = null,
    varargs = true,
    known = true,
).also { OPCODES[0x40] = it }

val OP_SWITCH_CALL = Opcode(
    code = 0x41,
    mnemonic = "switch_call",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(ILabelVarType, null, null, false, false),
    ),
    stack = null,
    varargs = true,
    known = true,
).also { OPCODES[0x41] = it }

val OP_STACK_PUSH = Opcode(
    code = 0x42,
    mnemonic = "stack_push",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x42] = it }

val OP_STACK_POP = Opcode(
    code = 0x43,
    mnemonic = "stack_pop",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x43] = it }

val OP_STACK_PUSHM = Opcode(
    code = 0x44,
    mnemonic = "stack_pushm",
    doc = "Pushes the values of an arbitrary amount of registers onto the stack.",
    params = listOf(
        Param(RegType(null), null, null, true, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x44] = it }

val OP_STACK_POPM = Opcode(
    code = 0x45,
    mnemonic = "stack_popm",
    doc = "Pops an arbitrary amount of values from the stack and writes them to registers.",
    params = listOf(
        Param(RegType(null), null, null, false, true),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x45] = it }

val OP_ARG_PUSHR = Opcode(
    code = 0x48,
    mnemonic = "arg_pushr",
    doc = "Pushes the value of the given register onto the stack.",
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x48] = it }

val OP_ARG_PUSHL = Opcode(
    code = 0x49,
    mnemonic = "arg_pushl",
    doc = "Pushes the given value onto the stack.",
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x49] = it }

val OP_ARG_PUSHB = Opcode(
    code = 0x4A,
    mnemonic = "arg_pushb",
    doc = "Pushes the given value onto the stack.",
    params = listOf(
        Param(ByteType, null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x4A] = it }

val OP_ARG_PUSHW = Opcode(
    code = 0x4B,
    mnemonic = "arg_pushw",
    doc = "Pushes the given value onto the stack.",
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x4B] = it }

val OP_ARG_PUSHA = Opcode(
    code = 0x4C,
    mnemonic = "arg_pusha",
    doc = "Pushes the memory address of the given register onto the stack. Not used by Sega.",
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x4C] = it }

val OP_ARG_PUSHO = Opcode(
    code = 0x4D,
    mnemonic = "arg_pusho",
    doc = "Pushes the memory address of the given label onto the stack. Not used by Sega.",
    params = listOf(
        Param(LabelType.Instance, null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x4D] = it }

val OP_ARG_PUSHS = Opcode(
    code = 0x4E,
    mnemonic = "arg_pushs",
    doc = "Pushes the given value onto the stack.",
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Push,
    varargs = false,
    known = true,
).also { OPCODES[0x4E] = it }

val OP_UNKNOWN_4F = Opcode(
    code = 0x4F,
    mnemonic = "unknown_4f",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES[0x4F] = it }

val OP_MESSAGE = Opcode(
    code = 0x50,
    mnemonic = "message",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x50] = it }

val OP_LIST = Opcode(
    code = 0x51,
    mnemonic = "list",
    doc = "Used to display a list of items and retrieve the item selected by the player.\nList items should be seperated by newlines. The selected item's index will be written to the given register.",
    params = listOf(
        Param(RegType(listOf(
            Param(ByteType, null, null, false, true),
        )), null, null, false, false),
        Param(StringType, "list", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x51] = it }

val OP_FADEIN = Opcode(
    code = 0x52,
    mnemonic = "fadein",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x52] = it }

val OP_FADEOUT = Opcode(
    code = 0x53,
    mnemonic = "fadeout",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x53] = it }

val OP_SE = Opcode(
    code = 0x54,
    mnemonic = "se",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x54] = it }

val OP_BGM = Opcode(
    code = 0x55,
    mnemonic = "bgm",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x55] = it }

val OP_ENABLE = Opcode(
    code = 0x58,
    mnemonic = "enable",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x58] = it }

val OP_DISABLE = Opcode(
    code = 0x59,
    mnemonic = "disable",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x59] = it }

val OP_WINDOW_MSG = Opcode(
    code = 0x5A,
    mnemonic = "window_msg",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x5A] = it }

val OP_ADD_MSG = Opcode(
    code = 0x5B,
    mnemonic = "add_msg",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x5B] = it }

val OP_MESEND = Opcode(
    code = 0x5C,
    mnemonic = "mesend",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x5C] = it }

val OP_GETTIME = Opcode(
    code = 0x5D,
    mnemonic = "gettime",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x5D] = it }

val OP_WINEND = Opcode(
    code = 0x5E,
    mnemonic = "winend",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x5E] = it }

val OP_NPC_CRT_V3 = Opcode(
    code = 0x60,
    mnemonic = "npc_crt_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x60] = it }

val OP_NPC_STOP = Opcode(
    code = 0x61,
    mnemonic = "npc_stop",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x61] = it }

val OP_NPC_PLAY = Opcode(
    code = 0x62,
    mnemonic = "npc_play",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x62] = it }

val OP_NPC_KILL = Opcode(
    code = 0x63,
    mnemonic = "npc_kill",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x63] = it }

val OP_NPC_NONT = Opcode(
    code = 0x64,
    mnemonic = "npc_nont",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x64] = it }

val OP_NPC_TALK = Opcode(
    code = 0x65,
    mnemonic = "npc_talk",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x65] = it }

val OP_NPC_CRP_V3 = Opcode(
    code = 0x66,
    mnemonic = "npc_crp_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
            Param(AnyType.Instance, null, null, true, false),
            Param(AnyType.Instance, null, null, true, false),
            Param(ILabelType, null, null, true, false),
            Param(AnyType.Instance, null, null, true, false),
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x66] = it }

val OP_CREATE_PIPE = Opcode(
    code = 0x68,
    mnemonic = "create_pipe",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x68] = it }

val OP_P_HPSTAT_V3 = Opcode(
    code = 0x69,
    mnemonic = "p_hpstat_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x69] = it }

val OP_P_DEAD_V3 = Opcode(
    code = 0x6A,
    mnemonic = "p_dead_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x6A] = it }

val OP_P_DISABLEWARP = Opcode(
    code = 0x6B,
    mnemonic = "p_disablewarp",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x6B] = it }

val OP_P_ENABLEWARP = Opcode(
    code = 0x6C,
    mnemonic = "p_enablewarp",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x6C] = it }

val OP_P_MOVE_V3 = Opcode(
    code = 0x6D,
    mnemonic = "p_move_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x6D] = it }

val OP_P_LOOK = Opcode(
    code = 0x6E,
    mnemonic = "p_look",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x6E] = it }

val OP_P_ACTION_DISABLE = Opcode(
    code = 0x70,
    mnemonic = "p_action_disable",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x70] = it }

val OP_P_ACTION_ENABLE = Opcode(
    code = 0x71,
    mnemonic = "p_action_enable",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x71] = it }

val OP_DISABLE_MOVEMENT1 = Opcode(
    code = 0x72,
    mnemonic = "disable_movement1",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x72] = it }

val OP_ENABLE_MOVEMENT1 = Opcode(
    code = 0x73,
    mnemonic = "enable_movement1",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x73] = it }

val OP_P_NONCOL = Opcode(
    code = 0x74,
    mnemonic = "p_noncol",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x74] = it }

val OP_P_COL = Opcode(
    code = 0x75,
    mnemonic = "p_col",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x75] = it }

val OP_P_SETPOS = Opcode(
    code = 0x76,
    mnemonic = "p_setpos",
    doc = "Sets a player's position.",
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, "x", null, true, false),
            Param(IntType, "y", null, true, false),
            Param(IntType, "z", null, true, false),
            Param(IntType, "y_rot", null, true, false),
        )), null, "Coordinates and rotation around y-axis.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x76] = it }

val OP_P_RETURN_GUILD = Opcode(
    code = 0x77,
    mnemonic = "p_return_guild",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x77] = it }

val OP_P_TALK_GUILD = Opcode(
    code = 0x78,
    mnemonic = "p_talk_guild",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x78] = it }

val OP_NPC_TALK_PL_V3 = Opcode(
    code = 0x79,
    mnemonic = "npc_talk_pl_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x79] = it }

val OP_NPC_TALK_KILL = Opcode(
    code = 0x7A,
    mnemonic = "npc_talk_kill",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x7A] = it }

val OP_NPC_CRTPK_V3 = Opcode(
    code = 0x7B,
    mnemonic = "npc_crtpk_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x7B] = it }

val OP_NPC_CRPPK_V3 = Opcode(
    code = 0x7C,
    mnemonic = "npc_crppk_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x7C] = it }

val OP_NPC_CRPTALK_V3 = Opcode(
    code = 0x7D,
    mnemonic = "npc_crptalk_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x7D] = it }

val OP_P_LOOK_AT_V1 = Opcode(
    code = 0x7E,
    mnemonic = "p_look_at_v1",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x7E] = it }

val OP_NPC_CRP_ID_V3 = Opcode(
    code = 0x7F,
    mnemonic = "npc_crp_id_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x7F] = it }

val OP_CAM_QUAKE = Opcode(
    code = 0x80,
    mnemonic = "cam_quake",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x80] = it }

val OP_CAM_ADJ = Opcode(
    code = 0x81,
    mnemonic = "cam_adj",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x81] = it }

val OP_CAM_ZMIN = Opcode(
    code = 0x82,
    mnemonic = "cam_zmin",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x82] = it }

val OP_CAM_ZMOUT = Opcode(
    code = 0x83,
    mnemonic = "cam_zmout",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x83] = it }

val OP_CAM_PAN_V3 = Opcode(
    code = 0x84,
    mnemonic = "cam_pan_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x84] = it }

val OP_GAME_LEV_SUPER = Opcode(
    code = 0x85,
    mnemonic = "game_lev_super",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x85] = it }

val OP_GAME_LEV_RESET = Opcode(
    code = 0x86,
    mnemonic = "game_lev_reset",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x86] = it }

val OP_POS_PIPE_V3 = Opcode(
    code = 0x87,
    mnemonic = "pos_pipe_v3",
    doc = "Create a telepipe at a specific position for the given player slot that takes players back to Pioneer 2 or the Lab.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "x", null, true, false),
            Param(IntType, "y", null, true, false),
            Param(IntType, "z", null, true, false),
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x87] = it }

val OP_IF_ZONE_CLEAR = Opcode(
    code = 0x88,
    mnemonic = "if_zone_clear",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x88] = it }

val OP_CHK_ENE_NUM = Opcode(
    code = 0x89,
    mnemonic = "chk_ene_num",
    doc = "Retrieves the amount of enemies killed during the quest.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x89] = it }

val OP_UNHIDE_OBJ = Opcode(
    code = 0x8A,
    mnemonic = "unhide_obj",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8A] = it }

val OP_UNHIDE_ENE = Opcode(
    code = 0x8B,
    mnemonic = "unhide_ene",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8B] = it }

val OP_AT_COORDS_CALL = Opcode(
    code = 0x8C,
    mnemonic = "at_coords_call",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(ILabelType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8C] = it }

val OP_AT_COORDS_TALK = Opcode(
    code = 0x8D,
    mnemonic = "at_coords_talk",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(ILabelType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8D] = it }

val OP_COL_NPCIN = Opcode(
    code = 0x8E,
    mnemonic = "col_npcin",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(ILabelType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8E] = it }

val OP_COL_NPCINR = Opcode(
    code = 0x8F,
    mnemonic = "col_npcinr",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x8F] = it }

val OP_SWITCH_ON = Opcode(
    code = 0x90,
    mnemonic = "switch_on",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x90] = it }

val OP_SWITCH_OFF = Opcode(
    code = 0x91,
    mnemonic = "switch_off",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x91] = it }

val OP_PLAYBGM_EPI = Opcode(
    code = 0x92,
    mnemonic = "playbgm_epi",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x92] = it }

val OP_SET_MAINWARP = Opcode(
    code = 0x93,
    mnemonic = "set_mainwarp",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x93] = it }

val OP_SET_OBJ_PARAM = Opcode(
    code = 0x94,
    mnemonic = "set_obj_param",
    doc = "Creates a targetable object.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "x", null, true, false),
            Param(IntType, "y", null, true, false),
            Param(IntType, "z", null, true, false),
            Param(IntType, "radius", null, true, false),
            Param(ILabelType, "func", null, true, false),
            Param(IntType, "cursor_y", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, "Object handle.", false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x94] = it }

val OP_SET_FLOOR_HANDLER = Opcode(
    code = 0x95,
    mnemonic = "set_floor_handler",
    doc = null,
    params = listOf(
        Param(IntType, null, "Floor number.", false, false),
        Param(ILabelType, null, "Handler function label.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x95] = it }

val OP_CLR_FLOOR_HANDLER = Opcode(
    code = 0x96,
    mnemonic = "clr_floor_handler",
    doc = null,
    params = listOf(
        Param(IntType, null, "Floor number.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0x96] = it }

val OP_COL_PLINAW = Opcode(
    code = 0x97,
    mnemonic = "col_plinaw",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x97] = it }

val OP_HUD_HIDE = Opcode(
    code = 0x98,
    mnemonic = "hud_hide",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x98] = it }

val OP_HUD_SHOW = Opcode(
    code = 0x99,
    mnemonic = "hud_show",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x99] = it }

val OP_CINE_ENABLE = Opcode(
    code = 0x9A,
    mnemonic = "cine_enable",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x9A] = it }

val OP_CINE_DISABLE = Opcode(
    code = 0x9B,
    mnemonic = "cine_disable",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0x9B] = it }

val OP_SET_QT_FAILURE = Opcode(
    code = 0xA1,
    mnemonic = "set_qt_failure",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA1] = it }

val OP_SET_QT_SUCCESS = Opcode(
    code = 0xA2,
    mnemonic = "set_qt_success",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA2] = it }

val OP_CLR_QT_FAILURE = Opcode(
    code = 0xA3,
    mnemonic = "clr_qt_failure",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA3] = it }

val OP_CLR_QT_SUCCESS = Opcode(
    code = 0xA4,
    mnemonic = "clr_qt_success",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA4] = it }

val OP_SET_QT_CANCEL = Opcode(
    code = 0xA5,
    mnemonic = "set_qt_cancel",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA5] = it }

val OP_CLR_QT_CANCEL = Opcode(
    code = 0xA6,
    mnemonic = "clr_qt_cancel",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA6] = it }

val OP_PL_WALK_V3 = Opcode(
    code = 0xA8,
    mnemonic = "pl_walk_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xA8] = it }

val OP_PL_ADD_MESETA = Opcode(
    code = 0xB0,
    mnemonic = "pl_add_meseta",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xB0] = it }

val OP_THREAD_STG = Opcode(
    code = 0xB1,
    mnemonic = "thread_stg",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB1] = it }

val OP_DEL_OBJ_PARAM = Opcode(
    code = 0xB2,
    mnemonic = "del_obj_param",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, "Object handle.", false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB2] = it }

val OP_ITEM_CREATE = Opcode(
    code = 0xB3,
    mnemonic = "item_create",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB3] = it }

val OP_ITEM_CREATE2 = Opcode(
    code = 0xB4,
    mnemonic = "item_create2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB4] = it }

val OP_ITEM_DELETE = Opcode(
    code = 0xB5,
    mnemonic = "item_delete",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB5] = it }

val OP_ITEM_DELETE2 = Opcode(
    code = 0xB6,
    mnemonic = "item_delete2",
    doc = "Deletes an item from the player's inventory.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB6] = it }

val OP_ITEM_CHECK = Opcode(
    code = 0xB7,
    mnemonic = "item_check",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB7] = it }

val OP_SETEVT = Opcode(
    code = 0xB8,
    mnemonic = "setevt",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xB8] = it }

val OP_GET_DIFFLVL = Opcode(
    code = 0xB9,
    mnemonic = "get_difflvl",
    doc = "Sets the given register to the current difficulty. 0 For normal, 1 for hard and 2 for both very hard and ultimate.\nUse get_difficulty_level2 if you want to differentiate between very hard and ultimate.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xB9] = it }

val OP_SET_QT_EXIT = Opcode(
    code = 0xBA,
    mnemonic = "set_qt_exit",
    doc = null,
    params = listOf(
        Param(ILabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xBA] = it }

val OP_CLR_QT_EXIT = Opcode(
    code = 0xBB,
    mnemonic = "clr_qt_exit",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xBB] = it }

val OP_PARTICLE_V3 = Opcode(
    code = 0xC0,
    mnemonic = "particle_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC0] = it }

val OP_NPC_TEXT = Opcode(
    code = 0xC1,
    mnemonic = "npc_text",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xC1] = it }

val OP_NPC_CHKWARP = Opcode(
    code = 0xC2,
    mnemonic = "npc_chkwarp",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC2] = it }

val OP_PL_PKOFF = Opcode(
    code = 0xC3,
    mnemonic = "pl_pkoff",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC3] = it }

val OP_MAP_DESIGNATE = Opcode(
    code = 0xC4,
    mnemonic = "map_designate",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC4] = it }

val OP_MASTERKEY_ON = Opcode(
    code = 0xC5,
    mnemonic = "masterkey_on",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC5] = it }

val OP_MASTERKEY_OFF = Opcode(
    code = 0xC6,
    mnemonic = "masterkey_off",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC6] = it }

val OP_WINDOW_TIME = Opcode(
    code = 0xC7,
    mnemonic = "window_time",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC7] = it }

val OP_WINEND_TIME = Opcode(
    code = 0xC8,
    mnemonic = "winend_time",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC8] = it }

val OP_WINSET_TIME = Opcode(
    code = 0xC9,
    mnemonic = "winset_time",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xC9] = it }

val OP_GETMTIME = Opcode(
    code = 0xCA,
    mnemonic = "getmtime",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xCA] = it }

val OP_SET_QUEST_BOARD_HANDLER = Opcode(
    code = 0xCB,
    mnemonic = "set_quest_board_handler",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xCB] = it }

val OP_CLEAR_QUEST_BOARD_HANDLER = Opcode(
    code = 0xCC,
    mnemonic = "clear_quest_board_handler",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xCC] = it }

val OP_PARTICLE_ID_V3 = Opcode(
    code = 0xCD,
    mnemonic = "particle_id_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xCD] = it }

val OP_NPC_CRPTALK_ID_V3 = Opcode(
    code = 0xCE,
    mnemonic = "npc_crptalk_id_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xCE] = it }

val OP_NPC_LANG_CLEAN = Opcode(
    code = 0xCF,
    mnemonic = "npc_lang_clean",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xCF] = it }

val OP_PL_PKON = Opcode(
    code = 0xD0,
    mnemonic = "pl_pkon",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD0] = it }

val OP_PL_CHK_ITEM2 = Opcode(
    code = 0xD1,
    mnemonic = "pl_chk_item2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD1] = it }

val OP_ENABLE_MAINMENU = Opcode(
    code = 0xD2,
    mnemonic = "enable_mainmenu",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD2] = it }

val OP_DISABLE_MAINMENU = Opcode(
    code = 0xD3,
    mnemonic = "disable_mainmenu",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD3] = it }

val OP_START_BATTLEBGM = Opcode(
    code = 0xD4,
    mnemonic = "start_battlebgm",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD4] = it }

val OP_END_BATTLEBGM = Opcode(
    code = 0xD5,
    mnemonic = "end_battlebgm",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD5] = it }

val OP_DISP_MSG_QB = Opcode(
    code = 0xD6,
    mnemonic = "disp_msg_qb",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xD6] = it }

val OP_CLOSE_MSG_QB = Opcode(
    code = 0xD7,
    mnemonic = "close_msg_qb",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD7] = it }

val OP_SET_EVENTFLAG_V3 = Opcode(
    code = 0xD8,
    mnemonic = "set_eventflag_v3",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xD8] = it }

val OP_SYNC_LETI = Opcode(
    code = 0xD9,
    mnemonic = "sync_leti",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xD9] = it }

val OP_SET_RETURNHUNTER = Opcode(
    code = 0xDA,
    mnemonic = "set_returnhunter",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xDA] = it }

val OP_SET_RETURNCITY = Opcode(
    code = 0xDB,
    mnemonic = "set_returncity",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xDB] = it }

val OP_LOAD_PVR = Opcode(
    code = 0xDC,
    mnemonic = "load_pvr",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xDC] = it }

val OP_LOAD_MIDI = Opcode(
    code = 0xDD,
    mnemonic = "load_midi",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xDD] = it }

val OP_UNKNOWN_DE = Opcode(
    code = 0xDE,
    mnemonic = "unknown_de",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xDE] = it }

val OP_NPC_PARAM_V3 = Opcode(
    code = 0xDF,
    mnemonic = "npc_param_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xDF] = it }

val OP_PAD_DRAGON = Opcode(
    code = 0xE0,
    mnemonic = "pad_dragon",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE0] = it }

val OP_CLEAR_MAINWARP = Opcode(
    code = 0xE1,
    mnemonic = "clear_mainwarp",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xE1] = it }

val OP_PCAM_PARAM_V3 = Opcode(
    code = 0xE2,
    mnemonic = "pcam_param_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE2] = it }

val OP_START_SETEVT_V3 = Opcode(
    code = 0xE3,
    mnemonic = "start_setevt_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xE3] = it }

val OP_WARP_ON = Opcode(
    code = 0xE4,
    mnemonic = "warp_on",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE4] = it }

val OP_WARP_OFF = Opcode(
    code = 0xE5,
    mnemonic = "warp_off",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE5] = it }

val OP_GET_SLOTNUMBER = Opcode(
    code = 0xE6,
    mnemonic = "get_slotnumber",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE6] = it }

val OP_GET_SERVERNUMBER = Opcode(
    code = 0xE7,
    mnemonic = "get_servernumber",
    doc = "Returns the index of the player who is the leader of the party.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE7] = it }

val OP_SET_EVENTFLAG2 = Opcode(
    code = 0xE8,
    mnemonic = "set_eventflag2",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xE8] = it }

val OP_RES = Opcode(
    code = 0xE9,
    mnemonic = "res",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xE9] = it }

val OP_UNKNOWN_EA = Opcode(
    code = 0xEA,
    mnemonic = "unknown_ea",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES[0xEA] = it }

val OP_ENABLE_BGMCTRL = Opcode(
    code = 0xEB,
    mnemonic = "enable_bgmctrl",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xEB] = it }

val OP_SW_SEND = Opcode(
    code = 0xEC,
    mnemonic = "sw_send",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xEC] = it }

val OP_CREATE_BGMCTRL = Opcode(
    code = 0xED,
    mnemonic = "create_bgmctrl",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xED] = it }

val OP_PL_ADD_MESETA2 = Opcode(
    code = 0xEE,
    mnemonic = "pl_add_meseta2",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xEE] = it }

val OP_SYNC_REGISTER = Opcode(
    code = 0xEF,
    mnemonic = "sync_register",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES[0xEF] = it }

val OP_SEND_REGWORK = Opcode(
    code = 0xF0,
    mnemonic = "send_regwork",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xF0] = it }

val OP_LETI_FIXED_CAMERA_V3 = Opcode(
    code = 0xF1,
    mnemonic = "leti_fixed_camera_v3",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xF1] = it }

val OP_DEFAULT_CAMERA_POS1 = Opcode(
    code = 0xF2,
    mnemonic = "default_camera_pos1",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xF2] = it }

val OP_GET_GC_NUMBER = Opcode(
    code = 0xFA,
    mnemonic = "get_gc_number",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES[0xFA] = it }

val OP_UNKNOWN_FB = Opcode(
    code = 0xFB,
    mnemonic = "unknown_fb",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES[0xFB] = it }

val OP_SET_CHAT_CALLBACK = Opcode(
    code = 0xF801,
    mnemonic = "set_chat_callback",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x01] = it }

val OP_GET_DIFFICULTY_LEVEL2 = Opcode(
    code = 0xF808,
    mnemonic = "get_difficulty_level2",
    doc = "Sets the given register to the current difficulty. 0 For normal, 1 for hard, 2 for very hard and 3 for ultimate.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x08] = it }

val OP_GET_NUMBER_OF_PLAYER1 = Opcode(
    code = 0xF809,
    mnemonic = "get_number_of_player1",
    doc = "Set the given register to the current number of players. Either 1, 2, 3 or 4.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x09] = it }

val OP_GET_COORD_OF_PLAYER = Opcode(
    code = 0xF80A,
    mnemonic = "get_coord_of_player",
    doc = "Retrieves a player's position.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "x", null, true, false),
            Param(IntType, "y", null, true, false),
            Param(IntType, "z", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x0A] = it }

val OP_ENABLE_MAP = Opcode(
    code = 0xF80B,
    mnemonic = "enable_map",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x0B] = it }

val OP_DISABLE_MAP = Opcode(
    code = 0xF80C,
    mnemonic = "disable_map",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x0C] = it }

val OP_MAP_DESIGNATE_EX = Opcode(
    code = 0xF80D,
    mnemonic = "map_designate_ex",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x0D] = it }

val OP_UNKNOWN_F80E = Opcode(
    code = 0xF80E,
    mnemonic = "unknown_f80e",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x0E] = it }

val OP_UNKNOWN_F80F = Opcode(
    code = 0xF80F,
    mnemonic = "unknown_f80f",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x0F] = it }

val OP_BA_INITIAL_FLOOR = Opcode(
    code = 0xF810,
    mnemonic = "ba_initial_floor",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x10] = it }

val OP_SET_BA_RULES = Opcode(
    code = 0xF811,
    mnemonic = "set_ba_rules",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x11] = it }

val OP_UNKNOWN_F812 = Opcode(
    code = 0xF812,
    mnemonic = "unknown_f812",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x12] = it }

val OP_UNKNOWN_F813 = Opcode(
    code = 0xF813,
    mnemonic = "unknown_f813",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x13] = it }

val OP_UNKNOWN_F814 = Opcode(
    code = 0xF814,
    mnemonic = "unknown_f814",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x14] = it }

val OP_UNKNOWN_F815 = Opcode(
    code = 0xF815,
    mnemonic = "unknown_f815",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x15] = it }

val OP_UNKNOWN_F816 = Opcode(
    code = 0xF816,
    mnemonic = "unknown_f816",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x16] = it }

val OP_UNKNOWN_F817 = Opcode(
    code = 0xF817,
    mnemonic = "unknown_f817",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x17] = it }

val OP_UNKNOWN_F818 = Opcode(
    code = 0xF818,
    mnemonic = "unknown_f818",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x18] = it }

val OP_UNKNOWN_F819 = Opcode(
    code = 0xF819,
    mnemonic = "unknown_f819",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x19] = it }

val OP_UNKNOWN_F81A = Opcode(
    code = 0xF81A,
    mnemonic = "unknown_f81a",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x1A] = it }

val OP_UNKNOWN_F81B = Opcode(
    code = 0xF81B,
    mnemonic = "unknown_f81b",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x1B] = it }

val OP_BA_DISP_MSG = Opcode(
    code = 0xF81C,
    mnemonic = "ba_disp_msg",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x1C] = it }

val OP_DEATH_LVL_UP = Opcode(
    code = 0xF81D,
    mnemonic = "death_lvl_up",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x1D] = it }

val OP_DEATH_TECH_LVL_UP = Opcode(
    code = 0xF81E,
    mnemonic = "death_tech_lvl_up",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x1E] = it }

val OP_CMODE_STAGE = Opcode(
    code = 0xF820,
    mnemonic = "cmode_stage",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x20] = it }

val OP_UNKNOWN_F823 = Opcode(
    code = 0xF823,
    mnemonic = "unknown_f823",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x23] = it }

val OP_UNKNOWN_F824 = Opcode(
    code = 0xF824,
    mnemonic = "unknown_f824",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x24] = it }

val OP_EXP_MULTIPLICATION = Opcode(
    code = 0xF825,
    mnemonic = "exp_multiplication",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x25] = it }

val OP_EXP_DIVISION = Opcode(
    code = 0xF826,
    mnemonic = "exp_division",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x26] = it }

val OP_GET_USER_IS_DEAD = Opcode(
    code = 0xF827,
    mnemonic = "get_user_is_dead",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x27] = it }

val OP_GO_FLOOR = Opcode(
    code = 0xF828,
    mnemonic = "go_floor",
    doc = "Sends a player to the given floor.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "floor_id", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x28] = it }

val OP_GET_NUM_KILLS = Opcode(
    code = 0xF829,
    mnemonic = "get_num_kills",
    doc = "Returns the number of enemies a player has killed during the quest.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "num_kills", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x29] = it }

val OP_UNLOCK_DOOR2 = Opcode(
    code = 0xF82B,
    mnemonic = "unlock_door2",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x2B] = it }

val OP_LOCK_DOOR2 = Opcode(
    code = 0xF82C,
    mnemonic = "lock_door2",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x2C] = it }

val OP_IF_SWITCH_NOT_PRESSED = Opcode(
    code = 0xF82D,
    mnemonic = "if_switch_not_pressed",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x2D] = it }

val OP_IF_SWITCH_PRESSED = Opcode(
    code = 0xF82E,
    mnemonic = "if_switch_pressed",
    doc = "Returns 1 if the switch is pressed, 0 otherwise.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "floor_id", null, true, false),
            Param(IntType, "switch_id", null, true, false),
            Param(IntType, "is_set", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x2E] = it }

val OP_UNKNOWN_F82F = Opcode(
    code = 0xF82F,
    mnemonic = "unknown_f82f",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x2F] = it }

val OP_CONTROL_DRAGON = Opcode(
    code = 0xF830,
    mnemonic = "control_dragon",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x30] = it }

val OP_RELEASE_DRAGON = Opcode(
    code = 0xF831,
    mnemonic = "release_dragon",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x31] = it }

val OP_SHRINK = Opcode(
    code = 0xF838,
    mnemonic = "shrink",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x38] = it }

val OP_UNSHRINK = Opcode(
    code = 0xF839,
    mnemonic = "unshrink",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x39] = it }

val OP_DISPLAY_CLOCK2 = Opcode(
    code = 0xF83C,
    mnemonic = "display_clock2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x3C] = it }

val OP_UNKNOWN_F83D = Opcode(
    code = 0xF83D,
    mnemonic = "unknown_f83d",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x3D] = it }

val OP_DELETE_AREA_TITLE = Opcode(
    code = 0xF83E,
    mnemonic = "delete_area_title",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x3E] = it }

val OP_LOAD_NPC_DATA = Opcode(
    code = 0xF840,
    mnemonic = "load_npc_data",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x40] = it }

val OP_GET_NPC_DATA = Opcode(
    code = 0xF841,
    mnemonic = "get_npc_data",
    doc = null,
    params = listOf(
        Param(DLabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x41] = it }

val OP_GIVE_DAMAGE_SCORE = Opcode(
    code = 0xF848,
    mnemonic = "give_damage_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x48] = it }

val OP_TAKE_DAMAGE_SCORE = Opcode(
    code = 0xF849,
    mnemonic = "take_damage_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x49] = it }

val OP_UNK_SCORE_F84A = Opcode(
    code = 0xF84A,
    mnemonic = "unk_score_f84a",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4A] = it }

val OP_UNK_SCORE_F84B = Opcode(
    code = 0xF84B,
    mnemonic = "unk_score_f84b",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4B] = it }

val OP_KILL_SCORE = Opcode(
    code = 0xF84C,
    mnemonic = "kill_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4C] = it }

val OP_DEATH_SCORE = Opcode(
    code = 0xF84D,
    mnemonic = "death_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4D] = it }

val OP_UNK_SCORE_F84E = Opcode(
    code = 0xF84E,
    mnemonic = "unk_score_f84e",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4E] = it }

val OP_ENEMY_DEATH_SCORE = Opcode(
    code = 0xF84F,
    mnemonic = "enemy_death_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x4F] = it }

val OP_MESETA_SCORE = Opcode(
    code = 0xF850,
    mnemonic = "meseta_score",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x50] = it }

val OP_UNKNOWN_F851 = Opcode(
    code = 0xF851,
    mnemonic = "unknown_f851",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x51] = it }

val OP_UNKNOWN_F852 = Opcode(
    code = 0xF852,
    mnemonic = "unknown_f852",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x52] = it }

val OP_REVERSE_WARPS = Opcode(
    code = 0xF853,
    mnemonic = "reverse_warps",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x53] = it }

val OP_UNREVERSE_WARPS = Opcode(
    code = 0xF854,
    mnemonic = "unreverse_warps",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x54] = it }

val OP_SET_ULT_MAP = Opcode(
    code = 0xF855,
    mnemonic = "set_ult_map",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x55] = it }

val OP_UNSET_ULT_MAP = Opcode(
    code = 0xF856,
    mnemonic = "unset_ult_map",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x56] = it }

val OP_SET_AREA_TITLE = Opcode(
    code = 0xF857,
    mnemonic = "set_area_title",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x57] = it }

val OP_EQUIP_ITEM = Opcode(
    code = 0xF85A,
    mnemonic = "equip_item",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x5A] = it }

val OP_UNEQUIP_ITEM = Opcode(
    code = 0xF85B,
    mnemonic = "unequip_item",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x5B] = it }

val OP_UNKNOWN_F85E = Opcode(
    code = 0xF85E,
    mnemonic = "unknown_f85e",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x5E] = it }

val OP_UNKNOWN_F85F = Opcode(
    code = 0xF85F,
    mnemonic = "unknown_f85f",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x5F] = it }

val OP_UNKNOWN_F860 = Opcode(
    code = 0xF860,
    mnemonic = "unknown_f860",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x60] = it }

val OP_UNKNOWN_F861 = Opcode(
    code = 0xF861,
    mnemonic = "unknown_f861",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x61] = it }

val OP_CMODE_RANK = Opcode(
    code = 0xF864,
    mnemonic = "cmode_rank",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x64] = it }

val OP_AWARD_ITEM_NAME = Opcode(
    code = 0xF865,
    mnemonic = "award_item_name",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x65] = it }

val OP_AWARD_ITEM_SELECT = Opcode(
    code = 0xF866,
    mnemonic = "award_item_select",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x66] = it }

val OP_AWARD_ITEM_GIVE_TO = Opcode(
    code = 0xF867,
    mnemonic = "award_item_give_to",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x67] = it }

val OP_UNKNOWN_F868 = Opcode(
    code = 0xF868,
    mnemonic = "unknown_f868",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x68] = it }

val OP_UNKNOWN_F869 = Opcode(
    code = 0xF869,
    mnemonic = "unknown_f869",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x69] = it }

val OP_ITEM_CREATE_CMODE = Opcode(
    code = 0xF86A,
    mnemonic = "item_create_cmode",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x6A] = it }

val OP_UNKNOWN_F86B = Opcode(
    code = 0xF86B,
    mnemonic = "unknown_f86b",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x6B] = it }

val OP_AWARD_ITEM_OK = Opcode(
    code = 0xF86C,
    mnemonic = "award_item_ok",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x6C] = it }

val OP_BA_SET_LIVES = Opcode(
    code = 0xF86F,
    mnemonic = "ba_set_lives",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x6F] = it }

val OP_BA_SET_TECH_LVL = Opcode(
    code = 0xF870,
    mnemonic = "ba_set_tech_lvl",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x70] = it }

val OP_BA_SET_LVL = Opcode(
    code = 0xF871,
    mnemonic = "ba_set_lvl",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x71] = it }

val OP_BA_SET_TIME_LIMIT = Opcode(
    code = 0xF872,
    mnemonic = "ba_set_time_limit",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x72] = it }

val OP_BOSS_IS_DEAD = Opcode(
    code = 0xF873,
    mnemonic = "boss_is_dead",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x73] = it }

val OP_ENABLE_TECHS = Opcode(
    code = 0xF877,
    mnemonic = "enable_techs",
    doc = "Enables technique use for the given player.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x77] = it }

val OP_DISABLE_TECHS = Opcode(
    code = 0xF878,
    mnemonic = "disable_techs",
    doc = "Disables technique use for the given player.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x78] = it }

val OP_GET_GENDER = Opcode(
    code = 0xF879,
    mnemonic = "get_gender",
    doc = "Retrieves the player's gender. 0 If male, 1 if female.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "gender", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x79] = it }

val OP_GET_CHARA_CLASS = Opcode(
    code = 0xF87A,
    mnemonic = "get_chara_class",
    doc = "Retrieves the player's race and character class. race will be 0 if human, 1 if newman and 2 if cast. class will be 0 if hunter, 1 if ranger and 2 if force.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "race", null, false, true),
            Param(IntType, "class", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x7A] = it }

val OP_TAKE_SLOT_MESETA = Opcode(
    code = 0xF87B,
    mnemonic = "take_slot_meseta",
    doc = "Takes an amount of meseta from a player's inventory. Returns 1 if the meseta was taken, 0 otherwise.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
            Param(IntType, "amount", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "taken", null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x7B] = it }

val OP_READ_GUILDCARD_FLAG = Opcode(
    code = 0xF87F,
    mnemonic = "read_guildcard_flag",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x7F] = it }

val OP_UNKNOWN_F880 = Opcode(
    code = 0xF880,
    mnemonic = "unknown_f880",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x80] = it }

val OP_GET_PL_NAME = Opcode(
    code = 0xF881,
    mnemonic = "get_pl_name",
    doc = "Sets the value of <pl_name> to the given player's name.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x81] = it }

val OP_GET_PL_JOB = Opcode(
    code = 0xF882,
    mnemonic = "get_pl_job",
    doc = "Sets the value of <pl_job> to the given player's job (Hunter/Ranger/Force).",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x82] = it }

val OP_UNKNOWN_F883 = Opcode(
    code = 0xF883,
    mnemonic = "unknown_f883",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x83] = it }

val OP_GET_PLAYER_STATUS = Opcode(
    code = 0xF88A,
    mnemonic = "get_player_status",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x8A] = it }

val OP_SEND_MAIL = Opcode(
    code = 0xF88B,
    mnemonic = "send_mail",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x8B] = it }

val OP_ONLINE_CHECK = Opcode(
    code = 0xF88C,
    mnemonic = "online_check",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x8C] = it }

val OP_CHL_SET_TIMERECORD = Opcode(
    code = 0xF88D,
    mnemonic = "chl_set_timerecord",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x8D] = it }

val OP_CHL_GET_TIMERECORD = Opcode(
    code = 0xF88E,
    mnemonic = "chl_get_timerecord",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x8E] = it }

val OP_UNKNOWN_F88F = Opcode(
    code = 0xF88F,
    mnemonic = "unknown_f88f",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x8F] = it }

val OP_LOAD_ENEMY_DATA = Opcode(
    code = 0xF891,
    mnemonic = "load_enemy_data",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x91] = it }

val OP_GET_PHYSICAL_DATA = Opcode(
    code = 0xF892,
    mnemonic = "get_physical_data",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x92] = it }

val OP_GET_ATTACK_DATA = Opcode(
    code = 0xF893,
    mnemonic = "get_attack_data",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x93] = it }

val OP_GET_RESIST_DATA = Opcode(
    code = 0xF894,
    mnemonic = "get_resist_data",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x94] = it }

val OP_GET_MOVEMENT_DATA = Opcode(
    code = 0xF895,
    mnemonic = "get_movement_data",
    doc = null,
    params = listOf(
        Param(ShortType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x95] = it }

val OP_SHIFT_LEFT = Opcode(
    code = 0xF898,
    mnemonic = "shift_left",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x98] = it }

val OP_SHIFT_RIGHT = Opcode(
    code = 0xF899,
    mnemonic = "shift_right",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x99] = it }

val OP_GET_RANDOM = Opcode(
    code = 0xF89A,
    mnemonic = "get_random",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x9A] = it }

val OP_RESET_MAP = Opcode(
    code = 0xF89B,
    mnemonic = "reset_map",
    doc = "Sets all registers to 0 and resets the quest.",
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x9B] = it }

val OP_DISP_CHL_RETRY_MENU = Opcode(
    code = 0xF89C,
    mnemonic = "disp_chl_retry_menu",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x9C] = it }

val OP_CHL_REVERSER = Opcode(
    code = 0xF89D,
    mnemonic = "chl_reverser",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0x9D] = it }

val OP_UNKNOWN_F89E = Opcode(
    code = 0xF89E,
    mnemonic = "unknown_f89e",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x9E] = it }

val OP_UNKNOWN_F89F = Opcode(
    code = 0xF89F,
    mnemonic = "unknown_f89f",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0x9F] = it }

val OP_UNKNOWN_F8A8 = Opcode(
    code = 0xF8A8,
    mnemonic = "unknown_f8a8",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xA8] = it }

val OP_UNKNOWN_F8A9 = Opcode(
    code = 0xF8A9,
    mnemonic = "unknown_f8a9",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xA9] = it }

val OP_GET_NUMBER_OF_PLAYER2 = Opcode(
    code = 0xF8AD,
    mnemonic = "get_number_of_player2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xAD] = it }

val OP_READ1 = Opcode(
    code = 0xF8B0,
    mnemonic = "read1",
    doc = "Reads a 1-byte value from an arbitrary location.",
    params = listOf(
        Param(RegType(listOf(
            Param(ByteType, null, null, false, true),
        )), null, "Register to store the result to.", false, false),
        Param(IntType, null, "Address to read from.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB0] = it }

val OP_READ2 = Opcode(
    code = 0xF8B1,
    mnemonic = "read2",
    doc = "Reads a 2-byte value from an arbitrary location.",
    params = listOf(
        Param(RegType(listOf(
            Param(ShortType, null, null, false, true),
        )), null, "Register to store the result to.", false, false),
        Param(IntType, null, "Address to read from.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB1] = it }

val OP_READ4 = Opcode(
    code = 0xF8B2,
    mnemonic = "read4",
    doc = "Reads a 4-byte value from an arbitrary location.",
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, "Register to store the result to.", false, false),
        Param(IntType, null, "Address to read from.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB2] = it }

val OP_WRITE1 = Opcode(
    code = 0xF8B3,
    mnemonic = "write1",
    doc = "Writes a 1-byte value to an arbitrary location.",
    params = listOf(
        Param(IntType, null, "Address to write to.", false, false),
        Param(ByteType, null, "Value to be written.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB3] = it }

val OP_WRITE2 = Opcode(
    code = 0xF8B4,
    mnemonic = "write2",
    doc = "Writes a 2-byte value to an arbitrary location.",
    params = listOf(
        Param(IntType, null, "Address to write to.", false, false),
        Param(ShortType, null, "Value to be written.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB4] = it }

val OP_WRITE4 = Opcode(
    code = 0xF8B5,
    mnemonic = "write4",
    doc = "Writes a 4-byte value to an arbitrary location.",
    params = listOf(
        Param(IntType, null, "Address to write to.", false, false),
        Param(IntType, null, "Value to be written.", false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB5] = it }

val OP_CHL_RECOVERY = Opcode(
    code = 0xF8B9,
    mnemonic = "chl_recovery",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xB9] = it }

val OP_SET_EPISODE = Opcode(
    code = 0xF8BC,
    mnemonic = "set_episode",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xBC] = it }

val OP_FILE_DL_REQ = Opcode(
    code = 0xF8C0,
    mnemonic = "file_dl_req",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC0] = it }

val OP_GET_DL_STATUS = Opcode(
    code = 0xF8C1,
    mnemonic = "get_dl_status",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC1] = it }

val OP_GBA_UNKNOWN4 = Opcode(
    code = 0xF8C2,
    mnemonic = "gba_unknown4",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC2] = it }

val OP_GET_GBA_STATE = Opcode(
    code = 0xF8C3,
    mnemonic = "get_gba_state",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC3] = it }

val OP_UNKNOWN_F8C4 = Opcode(
    code = 0xF8C4,
    mnemonic = "unknown_f8c4",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xC4] = it }

val OP_UNKNOWN_F8C5 = Opcode(
    code = 0xF8C5,
    mnemonic = "unknown_f8c5",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xC5] = it }

val OP_QEXIT = Opcode(
    code = 0xF8C6,
    mnemonic = "qexit",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC6] = it }

val OP_USE_ANIMATION = Opcode(
    code = 0xF8C7,
    mnemonic = "use_animation",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, "animation", null, true, false),
            Param(IntType, "duration", null, true, false),
        )), null, "Animation ID and duration in number of frames.", false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC7] = it }

val OP_STOP_ANIMATION = Opcode(
    code = 0xF8C8,
    mnemonic = "stop_animation",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC8] = it }

val OP_RUN_TO_COORD = Opcode(
    code = 0xF8C9,
    mnemonic = "run_to_coord",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xC9] = it }

val OP_SET_SLOT_INVINCIBLE = Opcode(
    code = 0xF8CA,
    mnemonic = "set_slot_invincible",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xCA] = it }

val OP_UNKNOWN_F8CB = Opcode(
    code = 0xF8CB,
    mnemonic = "unknown_f8cb",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xCB] = it }

val OP_SET_SLOT_POISON = Opcode(
    code = 0xF8CC,
    mnemonic = "set_slot_poison",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xCC] = it }

val OP_SET_SLOT_PARALYZE = Opcode(
    code = 0xF8CD,
    mnemonic = "set_slot_paralyze",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xCD] = it }

val OP_SET_SLOT_SHOCK = Opcode(
    code = 0xF8CE,
    mnemonic = "set_slot_shock",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xCE] = it }

val OP_SET_SLOT_FREEZE = Opcode(
    code = 0xF8CF,
    mnemonic = "set_slot_freeze",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xCF] = it }

val OP_SET_SLOT_SLOW = Opcode(
    code = 0xF8D0,
    mnemonic = "set_slot_slow",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD0] = it }

val OP_SET_SLOT_CONFUSE = Opcode(
    code = 0xF8D1,
    mnemonic = "set_slot_confuse",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD1] = it }

val OP_SET_SLOT_SHIFTA = Opcode(
    code = 0xF8D2,
    mnemonic = "set_slot_shifta",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD2] = it }

val OP_SET_SLOT_DEBAND = Opcode(
    code = 0xF8D3,
    mnemonic = "set_slot_deband",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD3] = it }

val OP_SET_SLOT_JELLEN = Opcode(
    code = 0xF8D4,
    mnemonic = "set_slot_jellen",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD4] = it }

val OP_SET_SLOT_ZALURE = Opcode(
    code = 0xF8D5,
    mnemonic = "set_slot_zalure",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD5] = it }

val OP_FLETI_FIXED_CAMERA = Opcode(
    code = 0xF8D6,
    mnemonic = "fleti_fixed_camera",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD6] = it }

val OP_FLETI_LOCKED_CAMERA = Opcode(
    code = 0xF8D7,
    mnemonic = "fleti_locked_camera",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD7] = it }

val OP_DEFAULT_CAMERA_POS2 = Opcode(
    code = 0xF8D8,
    mnemonic = "default_camera_pos2",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD8] = it }

val OP_SET_MOTION_BLUR = Opcode(
    code = 0xF8D9,
    mnemonic = "set_motion_blur",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xD9] = it }

val OP_SET_SCREEN_BW = Opcode(
    code = 0xF8DA,
    mnemonic = "set_screen_bw",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xDA] = it }

val OP_UNKNOWN_F8DB = Opcode(
    code = 0xF8DB,
    mnemonic = "unknown_f8db",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(ShortType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xDB] = it }

val OP_NPC_ACTION_STRING = Opcode(
    code = 0xF8DC,
    mnemonic = "npc_action_string",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(SLabelType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xDC] = it }

val OP_GET_PAD_COND = Opcode(
    code = 0xF8DD,
    mnemonic = "get_pad_cond",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xDD] = it }

val OP_GET_BUTTON_COND = Opcode(
    code = 0xF8DE,
    mnemonic = "get_button_cond",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xDE] = it }

val OP_FREEZE_ENEMIES = Opcode(
    code = 0xF8DF,
    mnemonic = "freeze_enemies",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xDF] = it }

val OP_UNFREEZE_ENEMIES = Opcode(
    code = 0xF8E0,
    mnemonic = "unfreeze_enemies",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE0] = it }

val OP_FREEZE_EVERYTHING = Opcode(
    code = 0xF8E1,
    mnemonic = "freeze_everything",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE1] = it }

val OP_UNFREEZE_EVERYTHING = Opcode(
    code = 0xF8E2,
    mnemonic = "unfreeze_everything",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE2] = it }

val OP_RESTORE_HP = Opcode(
    code = 0xF8E3,
    mnemonic = "restore_hp",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE3] = it }

val OP_RESTORE_TP = Opcode(
    code = 0xF8E4,
    mnemonic = "restore_tp",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE4] = it }

val OP_CLOSE_CHAT_BUBBLE = Opcode(
    code = 0xF8E5,
    mnemonic = "close_chat_bubble",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE5] = it }

val OP_MOVE_COORDS_OBJECT = Opcode(
    code = 0xF8E6,
    mnemonic = "move_coords_object",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE6] = it }

val OP_AT_COORDS_CALL_EX = Opcode(
    code = 0xF8E7,
    mnemonic = "at_coords_call_ex",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xE7] = it }

val OP_UNKNOWN_F8E8 = Opcode(
    code = 0xF8E8,
    mnemonic = "unknown_f8e8",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xE8] = it }

val OP_UNKNOWN_F8E9 = Opcode(
    code = 0xF8E9,
    mnemonic = "unknown_f8e9",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xE9] = it }

val OP_UNKNOWN_F8EA = Opcode(
    code = 0xF8EA,
    mnemonic = "unknown_f8ea",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xEA] = it }

val OP_UNKNOWN_F8EB = Opcode(
    code = 0xF8EB,
    mnemonic = "unknown_f8eb",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xEB] = it }

val OP_UNKNOWN_F8EC = Opcode(
    code = 0xF8EC,
    mnemonic = "unknown_f8ec",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F8[0xEC] = it }

val OP_ANIMATION_CHECK = Opcode(
    code = 0xF8ED,
    mnemonic = "animation_check",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xED] = it }

val OP_CALL_IMAGE_DATA = Opcode(
    code = 0xF8EE,
    mnemonic = "call_image_data",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(ShortType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xEE] = it }

val OP_TURN_OFF_BGM_P2 = Opcode(
    code = 0xF8F0,
    mnemonic = "turn_off_bgm_p2",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xF0] = it }

val OP_TURN_ON_BGM_P2 = Opcode(
    code = 0xF8F1,
    mnemonic = "turn_on_bgm_p2",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xF1] = it }

val OP_LOAD_UNK_DATA = Opcode(
    code = 0xF8F2,
    mnemonic = "load_unk_data",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(DLabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xF2] = it }

val OP_PARTICLE2 = Opcode(
    code = 0xF8F3,
    mnemonic = "particle2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F8[0xF3] = it }

val OP_DEC2FLOAT = Opcode(
    code = 0xF901,
    mnemonic = "dec2float",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x01] = it }

val OP_FLOAT2DEC = Opcode(
    code = 0xF902,
    mnemonic = "float2dec",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x02] = it }

val OP_FLET = Opcode(
    code = 0xF903,
    mnemonic = "flet",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x03] = it }

val OP_FLETI = Opcode(
    code = 0xF904,
    mnemonic = "fleti",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x04] = it }

val OP_FADD = Opcode(
    code = 0xF908,
    mnemonic = "fadd",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x08] = it }

val OP_FADDI = Opcode(
    code = 0xF909,
    mnemonic = "faddi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x09] = it }

val OP_FSUB = Opcode(
    code = 0xF90A,
    mnemonic = "fsub",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0A] = it }

val OP_FSUBI = Opcode(
    code = 0xF90B,
    mnemonic = "fsubi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0B] = it }

val OP_FMUL = Opcode(
    code = 0xF90C,
    mnemonic = "fmul",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0C] = it }

val OP_FMULI = Opcode(
    code = 0xF90D,
    mnemonic = "fmuli",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0D] = it }

val OP_FDIV = Opcode(
    code = 0xF90E,
    mnemonic = "fdiv",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(FloatType, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0E] = it }

val OP_FDIVI = Opcode(
    code = 0xF90F,
    mnemonic = "fdivi",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(FloatType, null, null, false, true),
        )), null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x0F] = it }

val OP_GET_UNKNOWN_COUNT = Opcode(
    code = 0xF910,
    mnemonic = "get_unknown_count",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x10] = it }

val OP_GET_STACKABLE_ITEM_COUNT = Opcode(
    code = 0xF911,
    mnemonic = "get_stackable_item_count",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, "slot", null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
            Param(IntType, null, null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x11] = it }

val OP_FREEZE_AND_HIDE_EQUIP = Opcode(
    code = 0xF912,
    mnemonic = "freeze_and_hide_equip",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x12] = it }

val OP_THAW_AND_SHOW_EQUIP = Opcode(
    code = 0xF913,
    mnemonic = "thaw_and_show_equip",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x13] = it }

val OP_SET_PALETTEX_CALLBACK = Opcode(
    code = 0xF914,
    mnemonic = "set_palettex_callback",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x14] = it }

val OP_ACTIVATE_PALETTEX = Opcode(
    code = 0xF915,
    mnemonic = "activate_palettex",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x15] = it }

val OP_ENABLE_PALETTEX = Opcode(
    code = 0xF916,
    mnemonic = "enable_palettex",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x16] = it }

val OP_RESTORE_PALETTEX = Opcode(
    code = 0xF917,
    mnemonic = "restore_palettex",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x17] = it }

val OP_DISABLE_PALETTEX = Opcode(
    code = 0xF918,
    mnemonic = "disable_palettex",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x18] = it }

val OP_GET_PALETTEX_ACTIVATED = Opcode(
    code = 0xF919,
    mnemonic = "get_palettex_activated",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x19] = it }

val OP_GET_UNKNOWN_PALETTEX_STATUS = Opcode(
    code = 0xF91A,
    mnemonic = "get_unknown_palettex_status",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1A] = it }

val OP_DISABLE_MOVEMENT2 = Opcode(
    code = 0xF91B,
    mnemonic = "disable_movement2",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1B] = it }

val OP_ENABLE_MOVEMENT2 = Opcode(
    code = 0xF91C,
    mnemonic = "enable_movement2",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1C] = it }

val OP_GET_TIME_PLAYED = Opcode(
    code = 0xF91D,
    mnemonic = "get_time_played",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1D] = it }

val OP_GET_GUILDCARD_TOTAL = Opcode(
    code = 0xF91E,
    mnemonic = "get_guildcard_total",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1E] = it }

val OP_GET_SLOT_MESETA = Opcode(
    code = 0xF91F,
    mnemonic = "get_slot_meseta",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x1F] = it }

val OP_GET_PLAYER_LEVEL = Opcode(
    code = 0xF920,
    mnemonic = "get_player_level",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x20] = it }

val OP_GET_SECTION_ID = Opcode(
    code = 0xF921,
    mnemonic = "get_section_id",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x21] = it }

val OP_GET_PLAYER_HP = Opcode(
    code = 0xF922,
    mnemonic = "get_player_hp",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, "max_hp", null, false, true),
            Param(IntType, "hp", null, false, true),
            Param(IntType, "max_tp", null, false, true),
            Param(IntType, "tp", null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x22] = it }

val OP_GET_FLOOR_NUMBER = Opcode(
    code = 0xF923,
    mnemonic = "get_floor_number",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x23] = it }

val OP_GET_COORD_PLAYER_DETECT = Opcode(
    code = 0xF924,
    mnemonic = "get_coord_player_detect",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, "slot", null, true, false),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, true, false),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x24] = it }

val OP_READ_GLOBAL_FLAG = Opcode(
    code = 0xF925,
    mnemonic = "read_global_flag",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x25] = it }

val OP_WRITE_GLOBAL_FLAG = Opcode(
    code = 0xF926,
    mnemonic = "write_global_flag",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x26] = it }

val OP_UNKNOWN_F927 = Opcode(
    code = 0xF927,
    mnemonic = "unknown_f927",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x27] = it }

val OP_FLOOR_PLAYER_DETECT = Opcode(
    code = 0xF928,
    mnemonic = "floor_player_detect",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
            Param(IntType, null, null, false, true),
            Param(IntType, null, null, false, true),
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x28] = it }

val OP_READ_DISK_FILE = Opcode(
    code = 0xF929,
    mnemonic = "read_disk_file",
    doc = null,
    params = listOf(
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x29] = it }

val OP_OPEN_PACK_SELECT = Opcode(
    code = 0xF92A,
    mnemonic = "open_pack_select",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x2A] = it }

val OP_ITEM_SELECT = Opcode(
    code = 0xF92B,
    mnemonic = "item_select",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x2B] = it }

val OP_GET_ITEM_ID = Opcode(
    code = 0xF92C,
    mnemonic = "get_item_id",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x2C] = it }

val OP_COLOR_CHANGE = Opcode(
    code = 0xF92D,
    mnemonic = "color_change",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x2D] = it }

val OP_SEND_STATISTIC = Opcode(
    code = 0xF92E,
    mnemonic = "send_statistic",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x2E] = it }

val OP_UNKNOWN_F92F = Opcode(
    code = 0xF92F,
    mnemonic = "unknown_f92f",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x2F] = it }

val OP_CHAT_BOX = Opcode(
    code = 0xF930,
    mnemonic = "chat_box",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x30] = it }

val OP_CHAT_BUBBLE = Opcode(
    code = 0xF931,
    mnemonic = "chat_bubble",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x31] = it }

val OP_UNKNOWN_F933 = Opcode(
    code = 0xF933,
    mnemonic = "unknown_f933",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x33] = it }

val OP_SCROLL_TEXT = Opcode(
    code = 0xF934,
    mnemonic = "scroll_text",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(FloatType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x34] = it }

val OP_GBA_UNKNOWN1 = Opcode(
    code = 0xF935,
    mnemonic = "gba_unknown1",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x35] = it }

val OP_GBA_UNKNOWN2 = Opcode(
    code = 0xF936,
    mnemonic = "gba_unknown2",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x36] = it }

val OP_GBA_UNKNOWN3 = Opcode(
    code = 0xF937,
    mnemonic = "gba_unknown3",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x37] = it }

val OP_ADD_DAMAGE_TO = Opcode(
    code = 0xF938,
    mnemonic = "add_damage_to",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x38] = it }

val OP_ITEM_DELETE3 = Opcode(
    code = 0xF939,
    mnemonic = "item_delete3",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x39] = it }

val OP_GET_ITEM_INFO = Opcode(
    code = 0xF93A,
    mnemonic = "get_item_info",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3A] = it }

val OP_ITEM_PACKING1 = Opcode(
    code = 0xF93B,
    mnemonic = "item_packing1",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3B] = it }

val OP_ITEM_PACKING2 = Opcode(
    code = 0xF93C,
    mnemonic = "item_packing2",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3C] = it }

val OP_GET_LANG_SETTING = Opcode(
    code = 0xF93D,
    mnemonic = "get_lang_setting",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(AnyType.Instance, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3D] = it }

val OP_PREPARE_STATISTIC = Opcode(
    code = 0xF93E,
    mnemonic = "prepare_statistic",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3E] = it }

val OP_KEYWORD_DETECT = Opcode(
    code = 0xF93F,
    mnemonic = "keyword_detect",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x3F] = it }

val OP_KEYWORD = Opcode(
    code = 0xF940,
    mnemonic = "keyword",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, "slot", null, false, false),
        Param(StringType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x40] = it }

val OP_GET_GUILDCARD_NUM = Opcode(
    code = 0xF941,
    mnemonic = "get_guildcard_num",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x41] = it }

val OP_GET_WRAP_STATUS = Opcode(
    code = 0xF944,
    mnemonic = "get_wrap_status",
    doc = null,
    params = listOf(
        Param(IntType, "slot", null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x44] = it }

val OP_INITIAL_FLOOR = Opcode(
    code = 0xF945,
    mnemonic = "initial_floor",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x45] = it }

val OP_SIN = Opcode(
    code = 0xF946,
    mnemonic = "sin",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x46] = it }

val OP_COS = Opcode(
    code = 0xF947,
    mnemonic = "cos",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x47] = it }

val OP_BOSS_IS_DEAD2 = Opcode(
    code = 0xF94A,
    mnemonic = "boss_is_dead2",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x4A] = it }

val OP_UNKNOWN_F94B = Opcode(
    code = 0xF94B,
    mnemonic = "unknown_f94b",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x4B] = it }

val OP_UNKNOWN_F94C = Opcode(
    code = 0xF94C,
    mnemonic = "unknown_f94c",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x4C] = it }

val OP_IS_THERE_CARDBATTLE = Opcode(
    code = 0xF94D,
    mnemonic = "is_there_cardbattle",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x4D] = it }

val OP_BB_P2_MENU = Opcode(
    code = 0xF950,
    mnemonic = "bb_p2_menu",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x50] = it }

val OP_BB_MAP_DESIGNATE = Opcode(
    code = 0xF951,
    mnemonic = "bb_map_designate",
    doc = null,
    params = listOf(
        Param(ByteType, null, null, false, false),
        Param(ShortType, null, null, false, false),
        Param(ByteType, null, null, false, false),
        Param(ByteType, null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x51] = it }

val OP_BB_GET_NUMBER_IN_PACK = Opcode(
    code = 0xF952,
    mnemonic = "bb_get_number_in_pack",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x52] = it }

val OP_BB_SWAP_ITEM = Opcode(
    code = 0xF953,
    mnemonic = "bb_swap_item",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x53] = it }

val OP_BB_CHECK_WRAP = Opcode(
    code = 0xF954,
    mnemonic = "bb_check_wrap",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x54] = it }

val OP_BB_EXCHANGE_PD_ITEM = Opcode(
    code = 0xF955,
    mnemonic = "bb_exchange_pd_item",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x55] = it }

val OP_BB_EXCHANGE_PD_SRANK = Opcode(
    code = 0xF956,
    mnemonic = "bb_exchange_pd_srank",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x56] = it }

val OP_BB_EXCHANGE_PD_SPECIAL = Opcode(
    code = 0xF957,
    mnemonic = "bb_exchange_pd_special",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x57] = it }

val OP_BB_EXCHANGE_PD_PERCENT = Opcode(
    code = 0xF958,
    mnemonic = "bb_exchange_pd_percent",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x58] = it }

val OP_UNKNOWN_F959 = Opcode(
    code = 0xF959,
    mnemonic = "unknown_f959",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x59] = it }

val OP_BB_EXCHANGE_SLT = Opcode(
    code = 0xF95C,
    mnemonic = "bb_exchange_slt",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x5C] = it }

val OP_BB_EXCHANGE_PC = Opcode(
    code = 0xF95D,
    mnemonic = "bb_exchange_pc",
    doc = null,
    params = emptyList(),
    stack = null,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x5D] = it }

val OP_BB_BOX_CREATE_BP = Opcode(
    code = 0xF95E,
    mnemonic = "bb_box_create_bp",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
        Param(FloatType, null, null, false, false),
        Param(FloatType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x5E] = it }

val OP_BB_EXCHANGE_PT = Opcode(
    code = 0xF95F,
    mnemonic = "bb_exchange_pt",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
        Param(IntType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
        Param(ILabelType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = true,
).also { OPCODES_F9[0x5F] = it }

val OP_UNKNOWN_F960 = Opcode(
    code = 0xF960,
    mnemonic = "unknown_f960",
    doc = null,
    params = listOf(
        Param(IntType, null, null, false, false),
    ),
    stack = StackInteraction.Pop,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x60] = it }

val OP_UNKNOWN_F961 = Opcode(
    code = 0xF961,
    mnemonic = "unknown_f961",
    doc = null,
    params = listOf(
        Param(RegType(listOf(
            Param(IntType, null, null, false, true),
        )), null, null, false, false),
    ),
    stack = null,
    varargs = false,
    known = false,
).also { OPCODES_F9[0x61] = it }
