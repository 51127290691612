package world.phantasmal.psolib.fileFormats.quest

fun objectTypeFromId(id: Short): ObjectType =
    when (id.toInt()) {
        0 -> ObjectType.PlayerSet
        1 -> ObjectType.Particle
        2 -> ObjectType.Teleporter
        3 -> ObjectType.Warp
        4 -> ObjectType.LightCollision
        5 -> ObjectType.Item
        6 -> ObjectType.EnvSound
        7 -> ObjectType.FogCollision
        8 -> ObjectType.EventCollision
        9 -> ObjectType.CharaCollision
        10 -> ObjectType.ElementalTrap
        11 -> ObjectType.StatusTrap
        12 -> ObjectType.HealTrap
        13 -> ObjectType.LargeElementalTrap
        14 -> ObjectType.ObjRoomID
        15 -> ObjectType.Sensor
        16 -> ObjectType.UnknownItem16
        17 -> ObjectType.LensFlare
        18 -> ObjectType.ScriptCollision
        19 -> ObjectType.HealRing
        20 -> ObjectType.MapCollision
        21 -> ObjectType.ScriptCollisionA
        22 -> ObjectType.ItemLight
        23 -> ObjectType.RadarCollision
        24 -> ObjectType.FogCollisionSW
        25 -> ObjectType.BossTeleporter
        26 -> ObjectType.ImageBoard
        27 -> ObjectType.QuestWarp
        28 -> ObjectType.Epilogue
        29 -> ObjectType.UnknownItem29
        30 -> ObjectType.UnknownItem30
        31 -> ObjectType.UnknownItem31
        32 -> ObjectType.BoxDetectObject
        33 -> ObjectType.SymbolChatObject
        34 -> ObjectType.TouchPlateObject
        35 -> ObjectType.TargetableObject
        36 -> ObjectType.EffectObject
        37 -> ObjectType.CountDownObject
        38 -> ObjectType.UnknownItem38
        39 -> ObjectType.UnknownItem39
        40 -> ObjectType.UnknownItem40
        41 -> ObjectType.UnknownItem41
        64 -> ObjectType.MenuActivation
        65 -> ObjectType.TelepipeLocation
        66 -> ObjectType.BGMCollision
        67 -> ObjectType.MainRagolTeleporter
        68 -> ObjectType.LobbyTeleporter
        69 -> ObjectType.PrincipalWarp
        70 -> ObjectType.ShopDoor
        71 -> ObjectType.HuntersGuildDoor
        72 -> ObjectType.TeleporterDoor
        73 -> ObjectType.MedicalCenterDoor
        74 -> ObjectType.Elevator
        75 -> ObjectType.EasterEgg
        76 -> ObjectType.ValentinesHeart
        77 -> ObjectType.ChristmasTree
        78 -> ObjectType.ChristmasWreath
        79 -> ObjectType.HalloweenPumpkin
        80 -> ObjectType.TwentyFirstCentury
        81 -> ObjectType.Sonic
        82 -> ObjectType.WelcomeBoard
        83 -> ObjectType.Firework
        84 -> ObjectType.LobbyScreenDoor
        85 -> ObjectType.MainRagolTeleporterBattleInNextArea
        86 -> ObjectType.LabTeleporterDoor
        87 -> ObjectType.Pioneer2InvisibleTouchplate
        128 -> ObjectType.ForestDoor
        129 -> ObjectType.ForestSwitch
        130 -> ObjectType.LaserFence
        131 -> ObjectType.LaserSquareFence
        132 -> ObjectType.ForestLaserFenceSwitch
        133 -> ObjectType.LightRays
        134 -> ObjectType.BlueButterfly
        135 -> ObjectType.Probe
        136 -> ObjectType.RandomTypeBox1
        137 -> ObjectType.ForestWeatherStation
        138 -> ObjectType.Battery
        139 -> ObjectType.ForestConsole
        140 -> ObjectType.BlackSlidingDoor
        141 -> ObjectType.RicoMessagePod
        142 -> ObjectType.EnergyBarrier
        143 -> ObjectType.ForestRisingBridge
        144 -> ObjectType.SwitchNoneDoor
        145 -> ObjectType.EnemyBoxGrey
        146 -> ObjectType.FixedTypeBox
        147 -> ObjectType.EnemyBoxBrown
        149 -> ObjectType.EmptyTypeBox
        150 -> ObjectType.LaserFenceEx
        151 -> ObjectType.LaserSquareFenceEx
        192 -> ObjectType.FloorPanel1
        193 -> ObjectType.Caves4ButtonDoor
        194 -> ObjectType.CavesNormalDoor
        195 -> ObjectType.CavesSmashingPillar
        196 -> ObjectType.CavesSign1
        197 -> ObjectType.CavesSign2
        198 -> ObjectType.CavesSign3
        199 -> ObjectType.HexagonalTank
        200 -> ObjectType.BrownPlatform
        201 -> ObjectType.WarningLightObject
        203 -> ObjectType.Rainbow
        204 -> ObjectType.FloatingJellyfish
        205 -> ObjectType.FloatingDragonfly
        206 -> ObjectType.CavesSwitchDoor
        207 -> ObjectType.RobotRechargeStation
        208 -> ObjectType.CavesCakeShop
        209 -> ObjectType.Caves1SmallRedRock
        210 -> ObjectType.Caves1MediumRedRock
        211 -> ObjectType.Caves1LargeRedRock
        212 -> ObjectType.Caves2SmallRock1
        213 -> ObjectType.Caves2MediumRock1
        214 -> ObjectType.Caves2LargeRock1
        215 -> ObjectType.Caves2SmallRock2
        216 -> ObjectType.Caves2MediumRock2
        217 -> ObjectType.Caves2LargeRock2
        218 -> ObjectType.Caves3SmallRock
        219 -> ObjectType.Caves3MediumRock
        220 -> ObjectType.Caves3LargeRock
        222 -> ObjectType.FloorPanel2
        223 -> ObjectType.DestructableRockCaves1
        224 -> ObjectType.DestructableRockCaves2
        225 -> ObjectType.DestructableRockCaves3
        256 -> ObjectType.MinesDoor
        257 -> ObjectType.FloorPanel3
        258 -> ObjectType.MinesSwitchDoor
        259 -> ObjectType.LargeCryoTube
        260 -> ObjectType.ComputerLikeCalus
        261 -> ObjectType.GreenScreenOpeningAndClosing
        262 -> ObjectType.FloatingRobot
        263 -> ObjectType.FloatingBlueLight
        264 -> ObjectType.SelfDestructingObject1
        265 -> ObjectType.SelfDestructingObject2
        266 -> ObjectType.SelfDestructingObject3
        267 -> ObjectType.SparkMachine
        268 -> ObjectType.MinesLargeFlashingCrate
        304 -> ObjectType.RuinsSeal
        320 -> ObjectType.RuinsTeleporter
        321 -> ObjectType.RuinsWarpSiteToSite
        322 -> ObjectType.RuinsSwitch
        323 -> ObjectType.FloorPanel4
        324 -> ObjectType.Ruins1Door
        325 -> ObjectType.Ruins3Door
        326 -> ObjectType.Ruins2Door
        327 -> ObjectType.Ruins11ButtonDoor
        328 -> ObjectType.Ruins21ButtonDoor
        329 -> ObjectType.Ruins31ButtonDoor
        330 -> ObjectType.Ruins4ButtonDoor
        331 -> ObjectType.Ruins2ButtonDoor
        332 -> ObjectType.RuinsSensor
        333 -> ObjectType.RuinsFenceSwitch
        334 -> ObjectType.RuinsLaserFence4x2
        335 -> ObjectType.RuinsLaserFence6x2
        336 -> ObjectType.RuinsLaserFence4x4
        337 -> ObjectType.RuinsLaserFence6x4
        338 -> ObjectType.RuinsPoisonBlob
        339 -> ObjectType.RuinsPillarTrap
        340 -> ObjectType.PopupTrapNoTech
        341 -> ObjectType.RuinsCrystal
        342 -> ObjectType.Monument
        345 -> ObjectType.RuinsRock1
        346 -> ObjectType.RuinsRock2
        347 -> ObjectType.RuinsRock3
        348 -> ObjectType.RuinsRock4
        349 -> ObjectType.RuinsRock5
        350 -> ObjectType.RuinsRock6
        351 -> ObjectType.RuinsRock7
        352 -> ObjectType.Poison
        353 -> ObjectType.FixedBoxTypeRuins
        354 -> ObjectType.RandomBoxTypeRuins
        355 -> ObjectType.EnemyTypeBoxYellow
        356 -> ObjectType.EnemyTypeBoxBlue
        357 -> ObjectType.EmptyTypeBoxBlue
        358 -> ObjectType.DestructableRock
        359 -> ObjectType.PopupTrapsTechs
        368 -> ObjectType.FlyingWhiteBird
        369 -> ObjectType.Tower
        370 -> ObjectType.FloatingRocks
        371 -> ObjectType.FloatingSoul
        372 -> ObjectType.Butterfly
        384 -> ObjectType.LobbyGameMenu
        385 -> ObjectType.LobbyWarpObject
        386 -> ObjectType.Lobby1EventObjectDefaultTree
        387 -> ObjectType.UnknownItem387
        388 -> ObjectType.UnknownItem388
        389 -> ObjectType.UnknownItem389
        390 -> ObjectType.LobbyEventObjectStaticPumpkin
        391 -> ObjectType.LobbyEventObject3ChristmasWindows
        392 -> ObjectType.LobbyEventObjectRedAndWhiteCurtain
        393 -> ObjectType.UnknownItem393
        394 -> ObjectType.UnknownItem394
        395 -> ObjectType.LobbyFishTank
        396 -> ObjectType.LobbyEventObjectButterflies
        400 -> ObjectType.UnknownItem400
        401 -> ObjectType.GreyWallLow
        402 -> ObjectType.SpaceshipDoor
        403 -> ObjectType.GreyWallHigh
        416 -> ObjectType.TempleNormalDoor
        417 -> ObjectType.BreakableWallWallButUnbreakable
        418 -> ObjectType.BrokenCylinderAndRubble
        419 -> ObjectType.ThreeBrokenWallPiecesOnFloor
        420 -> ObjectType.HighBrickCylinder
        421 -> ObjectType.LyingCylinder
        422 -> ObjectType.BrickConeWithFlatTop
        423 -> ObjectType.BreakableTempleWall
        424 -> ObjectType.TempleMapDetect
        425 -> ObjectType.SmallBrownBrickRisingBridge
        426 -> ObjectType.LongRisingBridgeWithPinkHighEdges
        427 -> ObjectType.FourSwitchTempleDoor
        448 -> ObjectType.FourButtonSpaceshipDoor
        512 -> ObjectType.ItemBoxCca
        513 -> ObjectType.TeleporterEp2
        514 -> ObjectType.CcaDoor
        515 -> ObjectType.SpecialBoxCca
        516 -> ObjectType.BigCcaDoor
        517 -> ObjectType.BigCcaDoorSwitch
        518 -> ObjectType.LittleRock
        519 -> ObjectType.Little3StoneWall
        520 -> ObjectType.Medium3StoneWall
        521 -> ObjectType.SpiderPlant
        522 -> ObjectType.CcaAreaTeleporter
        523 -> ObjectType.UnknownItem523
        524 -> ObjectType.WhiteBird
        525 -> ObjectType.OrangeBird
        527 -> ObjectType.Saw
        528 -> ObjectType.LaserDetect
        529 -> ObjectType.UnknownItem529
        530 -> ObjectType.UnknownItem530
        531 -> ObjectType.Seagull
        544 -> ObjectType.Fish
        545 -> ObjectType.SeabedDoorWithBlueEdges
        546 -> ObjectType.SeabedDoorAlwaysOpenNonTriggerable
        547 -> ObjectType.LittleCryotube
        548 -> ObjectType.WideGlassWallBreakable
        549 -> ObjectType.BlueFloatingRobot
        550 -> ObjectType.RedFloatingRobot
        551 -> ObjectType.Dolphin
        552 -> ObjectType.CaptureTrap
        553 -> ObjectType.VRLink
        576 -> ObjectType.UnknownItem576
        640 -> ObjectType.WarpInBarbaRayRoom
        672 -> ObjectType.UnknownItem672
        688 -> ObjectType.GeeNest
        689 -> ObjectType.LabComputerConsole
        690 -> ObjectType.LabComputerConsoleGreenScreen
        691 -> ObjectType.ChairYellowPillow
        692 -> ObjectType.OrangeWallWithHoleInMiddle
        693 -> ObjectType.GreyWallWithHoleInMiddle
        694 -> ObjectType.LongTable
        695 -> ObjectType.GBAStation
        696 -> ObjectType.TalkLinkToSupport
        697 -> ObjectType.InstaWarp
        698 -> ObjectType.LabInvisibleObject
        699 -> ObjectType.LabGlassWindowDoor
        700 -> ObjectType.UnknownItem700
        701 -> ObjectType.LabCeilingWarp
        768 -> ObjectType.Ep4LightSource
        769 -> ObjectType.Cactus
        770 -> ObjectType.BigBrownRock
        771 -> ObjectType.BreakableBrownRock
        832 -> ObjectType.UnknownItem832
        833 -> ObjectType.UnknownItem833
        896 -> ObjectType.PoisonPlant
        897 -> ObjectType.UnknownItem897
        898 -> ObjectType.UnknownItem898
        899 -> ObjectType.OozingDesertPlant
        901 -> ObjectType.UnknownItem901
        902 -> ObjectType.BigBlackRocks
        903 -> ObjectType.UnknownItem903
        904 -> ObjectType.UnknownItem904
        905 -> ObjectType.UnknownItem905
        906 -> ObjectType.UnknownItem906
        907 -> ObjectType.FallingRock
        908 -> ObjectType.DesertPlantHasCollision
        909 -> ObjectType.DesertFixedTypeBoxBreakableCrystals
        910 -> ObjectType.UnknownItem910
        911 -> ObjectType.BeeHive
        912 -> ObjectType.UnknownItem912
        913 -> ObjectType.Heat
        960 -> ObjectType.TopOfSaintMillionEgg
        961 -> ObjectType.UnknownItem961
        else -> ObjectType.Unknown
    }
